.login-wrapper {
    height: 100vh !important;
    width: 100vw !important;

    display: flex;
    align-items: center;
    justify-content: center;
    /* background: url("../assests/login.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-color: rgb(6 6 6 / 70%);
    background-blend-mode: soft-light;
    /*  */
}

.login-container {
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem;
    background-color: #252836;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-container img {
    width: 100%;
}

.Login_input {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.Login_fields{
    margin-top: 30px;
}

.Login_input input {
    margin-bottom: 10px;
    padding: 4px;
    margin-top: 5px;
    width: 261px !important;
}

.Login_input label {
    color: white;
    margin-bottom: 10px;
}

.login-container p:hover {
    cursor: pointer;
}

.login-container button {
    margin-top: 25px;
    background-color: #1e1d2a;
    box-shadow: none !important;
    color: white;
    border: none;
    padding: 10px 30px;
}

.login-container p {
    color: white;
}