.react-tel-input  .form-control {
    width: 280px !important;
    border: 1px solid white;
    background-color: rgb(255, 255, 255,0.6) !important;
    border-radius: 5px;
    height: 40px !important;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: rgb(255, 255, 255,0.6) !important;
    border: 1px solid white !important;
    border-radius: 3px 0 0 3px;
}

input{
    width: 80% !important;
}

.selected{
  border: 3px solid rgb(255, 208, 0) !important;
  box-shadow: 0 0 0px 1px #4e4e4e61 !important;
}