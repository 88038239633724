.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.card-set-up {
  background-color: rgb(255, 255, 255, 0.6);
  border: 2px solid white;
  /* margin-top: 3rem; */
  border-radius: 14px;
  padding: 1rem 1.5rem;
  backdrop-filter: blur(2px);
  /* -webkit-filter: blur(4px); */
  color: #292929;
}

.card-width-200 {
  width: 280px;
  height: 300px;
}
.card-width-300 {
  width: 320px;
  height: 590px;
}
.card-height-200 {
  width: 350px;
  height: 400px;
}
.card-height-100 {
  width: 280px;
  height: 350px;
}
.card-height-250 {
  width: 280px;
  height: 420px;
}
.card-height-300 {
  width: 300px;
  height: 470px;
}

.card-height-350 {
  width: 280px;
  height: 500px;
}

.card-height-500 {
  width: 280px;
  height: 400px;
}

.text-center {
  text-align: center;
}

.qr-image {
  width: 200px;
  height: 200px;
}

.qr-center {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: relative;
  top: 35px;
}

/* Terms */

.my-1 {
  margin: 10px 0px 10px 0px;
}
.my-2 {
  margin: 20px 0px 20px 0px;
}

.my-3 {
  margin: 30px 0px 30px 0px;
}
.my-4 {
  margin: 40px 0px 40px 0px;
}
.my-5 {
  margin: 50px 0px 50px 0px;
}

.mx-1 {
  margin: 0px 10px 0px 10px;
}
.mx-2 {
  margin: 0px 20px 0px 20px;
}
.mx-3 {
  margin: 0px 30px 0px 30px;
}

.terms-conditions p {
  font-size: 14px;
  /* font-weight: bold; */
}

.checkbox {
  width: 25px !important;
  height: 25px !important;
  border: 2px solid black;
  margin-top: 12px;
  background-color: rgb(255, 255, 255, 0.6);
}

.d-flex {
  display: flex;
}
.d-flex p {
  font-size: 14px;
  font-weight: bold;
}

.text-white {
  color: white;
}

.btn-locker {
  width: 277px;
  height: 45px;
  background-color: #c3c1d0;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 19px;
}

.btn-lockernew {
  width: 160px;
  height: 45px;
  background-color: #c3c1d0;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 60px;
}

.my-4 {
  margin: 100px 0px 100px 0px;
}

.slot-head {
  display: flex;
  gap: 40px;
  padding: 3rem 0rem 3rem 0rem;
  justify-content: center;
  /* padding: 1rem; */
}
.slot {
  width: 140px;
  height: 130px;
  border: 1px solid white;
  background-color: rgb(255, 255, 255, 0.6);
  /* backdrop-filter: blur(2px); */
  border-radius: 8px;
  padding: 2.5px;
}

.slot-head p,
h3 {
  margin: 3px 0px 3px 0px;
  font-weight: 400;
  margin-top: 5px;
}

.slot-para {
  font-size: 18px;
  font-weight: 100 !important;
}

.success-card {
  width: 275px;
  height: 170px;
  border: 1.5px solid whitesmoke;
  border-radius: 7px;
}

.space {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.text-proceed {
  font-size: 15px;
  margin: 5px;
}

.marginTop {
  margin-top: 45px;
}

.success-card h2 {
  font-weight: 400;
  font-size: 22px;
}

.success-card h1 {
  font-size: 50px;
}

.bold {
  font-weight: bold;
}

.input-detail {
  width: 270px !important;
  height: 40px;

  background-color: rgb(255, 255, 255, 0.6) !important;
  border-radius: 5px;

  border-left: none;

  border: 1px solid white;
}
.input-name {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none !important ;
}

.justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.icon-input {
  height: 33px;
  width: 1.5rem;
  /* background-color: red; */
  background-color: rgb(255, 255, 255, 0.6) !important;
  border: 1px solid white;
  border-right: none !important;
  padding: 4px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

input {
  background-color: rgb(255, 255, 255, 0.6);
  border: none;
  border-radius: 5px;
  height: 39px !important;
  width: 39px !important;
  border: 1px solid white;
  /* margin-right: 30px !important; */
}

.color-select {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.red {
  border-radius: 5px;
  height: 40px !important;
  width: 40px !important;
  background-color: #ff0000 !important;
}

.green {
  border-radius: 5px;
  height: 40px !important;
  width: 40px !important;
  background-color: #00ff00 !important;
}

.blue {
  border-radius: 5px;
  height: 40px !important;
  width: 40px !important;
  background-color: #0000ff !important;
}

.opp-img-head {
  display: flex;
  justify-content: center;
}
.img-fluid {
  width: 203.1px;
  height: 255px;
  transform: rotate(27deg);
  margin-left: 38px;
}

input:focus {
  border: 1px solid white;
  box-shadow: none;
  outline-offset: 0px;
  outline: none;
}

input[type='radio'] {
  border: 1px solid #fff;
  padding: 0.5em;
  -webkit-appearance: none;
}

/* input[type=radio]:checked {
  background: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat center center;
  background-size: 9px 9px;
} */

/* input[type=radio]:focus {
  outline-color: transparent;
} */

.overflow-auto {
  overflow: auto;
  position: relative;
  width: 320px;
  height: 280px;
  line-height: 1.5rem;
  text-align: left;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.abs {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  padding: 0 1rem 1rem 1rem;
  border-radius: 0 0 10px 10px;
}

.text-gray {
  color: #a5a5a5;
}
