.main-container{
  display: flex;
  flex-direction: column;
  /* background: url('./assests/sentosa-bg.jpg'); */
  background-repeat: no-repeat;
  background-color: lightblue;
  background-size: cover;
}

.menu-container{
  margin-top: 1rem;
}

.text-center{
  text-align: center;
}


