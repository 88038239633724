.All_lockers {
  display: flex;
  /* grid-template-columns: repeat(4, 1fr); */
  /* grid-gap: 1rem; */
  flex-wrap: wrap;
  max-width: 500px !important;
}
.Locker {
  background-color: #fafafa;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 100px;
}
.BigCard {
  max-height: 500px;
  max-width: 480px !important;
  overflow: scroll;
  padding: 2rem;
}
.BigCard::-webkit-scrollbar{
  display: none;
}
.All_lockers p {
  margin: 0 !important;
  margin-bottom: 10px !important;
}
.Locker_icon {
  margin-bottom: 10px !important;
}
.BigCard h3 {
  margin-bottom: 20px;
}
.Locker button {
}
.Due_Amount {
  padding: 10px;
  float: right;
}
.Due_pay {
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
}
.Exceed_time {
  font-size: 12px;
}
.Modal_head {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.Password_input {
  background-color: transparent !important;
  border: 1px solid black;
  width: 200px !important;
  margin: 20px 0;
}
.Enter_password {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px !important;
}
.Enter_password button {
  background-color: transparent;
  border: 1px solid black;
  padding: 10px 30px;
}
.Enter_password button:hover {
  background-color: rgba(215, 215, 215, 0.831);
  cursor: pointer;
}
.Card_actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}
.Card_actions button {
  width: 133px;
  margin: 0 10px;
}
.extendTime button{
  border: none;
  outline: none;
  padding: .5rem 1rem;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 1rem;
}
.extendTime{
  text-align: center;
}