.model-window-all{
    right: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #7e7e7e5c;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.model-window-all-card{
    min-height: 300px;
    width: 465px;
    background-color: #cb1a46;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    color: #fff;
}
.model-window-all-card svg{
    font-size: 80px;
    color: #ffcb6d;

}
.btn-con{
    gap: 1rem;
    display: flex;
}

.btn-con button{
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.btn-con button:hover{
    background-color: #ebc585;
    font-weight: 900;
}