body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  /* box-sizing: border-box; */
}
.payment {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
